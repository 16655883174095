<template>
  <div class="row">
    <div class="col">
      <div class="card">
        <div
          class="card-header d-flex justify-content-between align-items-center"
        >
          <h3 class="card-title"></h3>
          <router-link
            v-if="can(uiPermissions.SUPPLIERS_TRADING_TERMS_CREATE)"
            :to="`/suppliers/${supplierId}/trading-terms/new`"
            data-test="btn-create-new"
            class="btn btn-primary ml-auto"
          >
            Create new
          </router-link>
        </div>
        <div class="card-body">
          <div class="card">
            <dimmer :active="loading">
              <table data-test="table-trading-terms" class="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Validity period</th>
                    <th>Created by</th>
                    <th>Updated by</th>
                    <th>Updated at</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="term in terms"
                    :key="term.id"
                    class="cursor-pointer"
                    @click="()=>navigate(term.id)">
                    <td>
                      <router-link
                        v-if="can(uiPermissions.SUPPLIERS_TRADING_TERMS_VIEW)"
                        :to="`/suppliers/${supplierId}/trading-terms/${term.id}`"
                      >
                        {{tradingTermName(term)}}
                      </router-link>
                      <span v-else>{{tradingTermName(term)}}</span>
                    </td>
                    <td>{{moment(term.valid_from).format('MMM DD, YY')}} to {{moment(term.valid_to).format('MMM DD, YY')}}</td>
                    <td>{{term.created_by.firstName}}</td>
                    <td>{{term.last_updated_by.firstName}}</td>
                    <td>{{term.updated_at}}</td>
                    <td class="text-capitalize" :class="statusColors[term.status]">{{term.status}}</td>
                  </tr>
                  <tr v-if="terms.length === 0">
                    <td colspan="100%" class="text-center">There are no results matching your criteria.</td>
                  </tr>
                </tbody>
              </table>
            </dimmer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {tradingTerms} from '@/services';
import loadsRelations from '@hellochef/shared-js/mixins/loadsRelations';

export default {
  name: 'TradingTerms',
  components: {},
  mixins: [loadsRelations],
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
    supplierName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      terms: [],
      with: ['createdBy', 'lastUpdatedBy'],
      statusColors: {
        'active': 'text-success',
        'draft': 'text-secondary',
        'expired': 'text-danger',
      },
    };
  },
  async created() {
    this.loading = true;
    try {
      const {trading_terms} = await tradingTerms
        .path(`suppliers/${this.supplierId}/trading-terms`)
        .get({params: {...this.withRelations()}});

      this.terms = trading_terms;
    }
    finally {
      this.loading = false;
    }
  },
  methods: {
    navigate(id) {
      this.$router.push(`/suppliers/${this.supplierId}/trading-terms/${id}`);
    },
    tradingTermName(term) {
      return this.supplierName.replaceAll(' ', '_') + '_' + this.moment(term.valid_to).format('YYYYMMDD');
    },
  },
};
</script>
