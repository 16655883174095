<template>
  <div class="card">
    <dimmer :active="loading">
      <table data-test="table-attachments" class="table table-hover">
        <thead>
          <tr>
            <th>Document type</th>
            <th>By</th>
            <th>Uploaded at</th>
            <th>Expiry</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <supplier-attachment-record
            v-for="document in documents"
            :key="document.key"
            :document="document"
            :attachments.sync="attachments"
            @upload="uploadAttachment"
          />
        </tbody>
      </table>
    </dimmer>
  </div>
</template>

<script>
import {suppliers} from '@/services';
import loadsRelations from '@hellochef/shared-js/mixins/loadsRelations';
import SupplierDocuments from '@/assets/enums/SupplierDocuments';
import SupplierAttachmentRecord from '@/views/suppliers/components/SupplierAttachmentRecord';

export default {
  name: 'Attachments',
  components: {SupplierAttachmentRecord},
  mixins: [loadsRelations],
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      attachments: {},
      with: ['user'],
      documents: SupplierDocuments,
    };
  },
  async created() {
    await this.fetchContacts();
  },
  methods: {
    async uploadAttachment(input) {
      this.loading = true;
      try {
        await suppliers.uploadAttachment(this.supplierId, input);
        await this.fetchContacts();
      }
      finally {
        this.loading = false;
      }
    },
    async fetchContacts() {
      this.loading = true;
      try {
        const {attachments} = await suppliers.getAttachments(
          this.supplierId,
          {params: {...this.withRelations()}},
        );
        this.attachments = attachments;
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>
