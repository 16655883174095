export default [
  {
    key: 'contract',
    name: 'Contract',
  },
  {
    key: 'service_level_agreement',
    name: 'Service level agreement',
  },
  {
    key: 'trading_license',
    name: 'Trading license',
  },
  {
    key: 'vat_certificate',
    name: 'VAT certificate',
  },
  {
    key: 'haccp_certificate',
    name: 'HACCP certificate',
  },
  {
    key: 'iso_certificate',
    name: 'ISO certificate',
  },
  {
    key: 'municipality_grade_report',
    name: 'Municipality grade report',
  },
  {
    key: 'fairtrade_certificate',
    name: 'Fairtrade certificate',
  },
  {
    key: 'credit_terms',
    name: 'Credit terms',
  },
];
