<template>
  <ul class="nav nav-tabs w-100 mx-0" role="tablist">
    <template v-for="t in tabs">
      <li
        v-if="can(t.permission)"
        :key="t.value"
        class="nav-item">
        <a
          class="nav-link"
          :class="{'active': tab === t.value}"
          @click="setTab(t.value)">{{t.title}}</a>
      </li>
    </template>
  </ul>
</template>

<script>
import Permissions from '@hellochef/shared-js/enums/Permissions';

export default {
  name: 'SupplierTabs',
  props: {
    tab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tabs: [
        {
          title: 'Details',
          value: 'details',
          permission: Permissions.SUPPLIERS_VIEW,
        },
        {
          title: 'Trading Terms',
          value: 'trading-terms',
          permission: Permissions.SUPPLIERS_TRADING_TERMS_VIEW,
        },
        {
          title: 'Attachments',
          value: 'attachments',
          permission: Permissions.SUPPLIERS_ATTACHMENTS_VIEW,
        },
      ],
    };
  },
  methods: {
    setTab(tab) {
      this.$emit('tab', tab);
    },
  },
};
</script>
