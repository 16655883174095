<template>
  <tr>
    <td><strong>{{document.name}}</strong></td>
    <template v-if="attachments.hasOwnProperty(document.key)">
      <td>{{firstAttachment.user.firstName}}</td>
      <td>{{firstAttachment.created_at}}</td>
      <td>{{firstAttachment.expires_at || '-'}}</td>
      <td>
        <div class="btn-group">
          <button
            v-if="can(uiPermissions.SUPPLIERS_ATTACHMENTS_CREATE)"
            v-b-tooltip="'Upload'"
            class="btn btn-outline-primary"
            @click.prevent="viewUploader(document.key)">
            <b-icon-upload/>
          </button>
          <a
            v-if="can(uiPermissions.SUPPLIERS_ATTACHMENTS_VIEW)"
            v-b-tooltip="'Download'"
            :href="firstAttachment.url"
            target="_blank"
            class="btn btn-outline-primary">
            <b-icon-download/>
          </a>
          <button
            v-if="attachments[document.key].length > 1"
            v-b-tooltip="'View document history'"
            class="btn btn-outline-primary"
            @click.prevent="viewHistory(document.key)">
            <b-icon-view-stacked/>
          </button>
        </div>
      </td>
    </template>
    <template v-else>
      <td></td>
      <td></td>
      <td></td>
      <td>
        <button
          v-if="can(uiPermissions.SUPPLIERS_ATTACHMENTS_CREATE)"
          v-b-tooltip="'Upload'"
          class="btn btn-outline-primary"
          @click.prevent="viewUploader(document.key)">
          <b-icon-upload/>
        </button>

      </td>
    </template>
    <b-modal
      v-model="showHistoryModal"
      hide-footer
      size="lg"
      :title="`${modalTitle} history`">
      <template #modal-header-close><wbr/></template>
      <table class="table table-hover">
        <thead>
          <tr>
            <th>By</th>
            <th>Uploaded at</th>
            <th>Expiry</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="attachment in attachments[activeType]" :key="attachment.id">
            <td>{{attachment.user.firstName}}</td>
            <td>{{attachment.created_at}}</td>
            <td>{{attachment.expires_at || '-'}}</td>
            <td>
              <a
                :href="attachment.url"
                target="_blank"
                class="btn btn-primary btn-sm">
                Download
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>
    <b-modal v-model="showUploadModal" :title="`${modalTitle} upload`">
      <template #modal-header-close><wbr/></template>
      <div class="mb-4">
        <file-input
          headerText="Add a PDF/JPG File"
          accept=".pdf,.jpeg,.jpg"
          @fileChange="file => selectedFile = file"
        />
      </div>
      <div class="form-group">
        <label class="form-label">Expiry date</label>
        <div class="input-group">
          <input
            v-model="expiryDate"
            type="date"
            data-test="txt-expiry-date"
            class="form-control">
        </div>
      </div>
      <template #modal-footer>
        <button
          data-test="btn-cancel"
          class="btn btn-lg btn-link"
          @click.prevent="showUploadModal = false">
          Cancel
        </button>
        <button
          data-test="btn-upload"
          class="btn btn-primary btn-lg"
          :disabled="!selectedFile"
          @click="upload">
          Upload
        </button>
      </template>
    </b-modal>
  </tr>
</template>

<script>
import {startCase} from 'lodash';
import FileInput from '@/components/FileInput';

export default {
  name: 'SupplierAttachmentRecord',
  components: {
    FileInput,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
    attachments: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      showUploadModal: false,
      showHistoryModal: false,
      activeType: null,
      selectedFile: null,
      expiryDate: null,
    };
  },
  computed: {
    modalTitle() {
      return startCase(this.activeType);
    },
    firstAttachment() {
      return this.attachments[this.document.key][0];
    },
  },
  methods: {
    async viewUploader(type) {
      this.showUploadModal = true;
      this.activeType = type;
    },
    upload() {
      this.$emit('upload', {
        file: this.selectedFile,
        document: this.activeType,
        expiry: this.expiryDate,
      });

      this.expiryDate = null;
      this.activeType = null;
      this.showUploadModal = false;
    },
    async viewHistory(type) {
      this.showHistoryModal = true;
      this.activeType = type;
    },
  },
};
</script>
