<template>
  <div class="container">
    <edit-header
      entity="supplier"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :isNew="isNew">
      <div class="d-flex align-items-center w-100">
        <router-link
          to="/suppliers"
          data-test="btn-back"
          class="btn btn-secondary mr-2">
          <i class="fe fe-chevron-left"></i> Back
        </router-link>
        <supplier-tabs
          v-if="!isNew"
          :tab="tab"
          @tab="setTab"/>
      </div>
    </edit-header>

    <dimmer :active="loading">
      <form
        v-if="tab === 'details'"
        v-disable-all="!can(uiPermissions.SUPPLIERS_UPDATE)"
        ref="form"
        data-test="form-supplier"
        @submit.prevent="submit">
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Basic information</h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label class="form-label">Legal name</label>
                    <input
                      v-model="item.legal_name"
                      type="text"
                      data-test="txt-legal-name"
                      :class="`form-control ${validator.errors().has('legal_name') ? 'is-invalid' : ''}`"/>
                    <small v-if="validator.errors().has('legal_name')" class="invalid-feedback">
                      {{validator.errors().first('legal_name')}}
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label">Trading name <span class="text-danger">*</span></label>
                    <input
                      v-model="item.name"
                      data-test="txt-trading-name"
                      :class="`form-control ${validator.errors().has('name') ? 'is-invalid' : ''}`"/>
                    <small v-if="validator.errors().has('name')" class="invalid-feedback">
                      {{validator.errors().first('name')}}
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label">Trading license number</label>
                    <input
                      v-model="item.trading_license_number"
                      data-test="txt-trading-license-number"
                      :class="`form-control ${validator.errors().has('trading_license_number') ? 'is-invalid' : ''}`"/>
                    <small v-if="validator.errors().has('trading_license_number')" class="invalid-feedback">
                      {{validator.errors().first('trading_license_number')}}
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label">Tax registration number</label>
                    <input
                      v-model="item.tax_registration_number"
                      data-test="txt-tax-registration-number"
                      :class="`form-control ${validator.errors().has('tax_registration_number') ? 'is-invalid' : ''}`"/>
                    <small v-if="validator.errors().has('tax_registration_number')" class="invalid-feedback">
                      {{validator.errors().first('tax_registration_number')}}
                    </small>
                  </div>
                  <div class="form-group col-md-3">
                    <label class="form-label">Country</label>
                    <select
                      v-model="item.country_id"
                      data-test="txt-country"
                      class="form-control">
                      <option :value="null">Select country</option>
                      <option
                        v-for="country in countries"
                        :key="country.id"
                        :value="country.id">
                        {{country.name}}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label class="form-label">City</label>
                    <input
                      v-model="item.city"
                      data-test="txt-city"
                      :class="`form-control ${validator.errors().has('city') ? 'is-invalid' : ''}`">
                    <small v-if="validator.errors().has('city')" class="invalid-feedback">
                      {{validator.errors().first('city')}}
                    </small>
                  </div>
                  <div class="form-group col-md-6">
                    <label class="form-label">Area</label>
                    <input
                      v-model="item.address"
                      data-test="txt-area"
                      :class="`form-control ${validator.errors().has('address') ? 'is-invalid' : ''}`">
                    <small v-if="validator.errors().has('address')" class="invalid-feedback">
                      {{validator.errors().first('address')}}
                    </small>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label">Delivery Slot</label>
                  <time-range-picker
                    v-model="delivery_slot"
                    data-test="txt-delivery-slot"
                    class="form-group mb-0"
                    @input="value => delivery_slot = value"></time-range-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Financial information</h3>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label class="form-label">Bank Account</label>
                  <input
                    v-model="item.bank_account"
                    data-test="txt-bank-account"
                    :class="`form-control ${validator.errors().has('bank_account') ? 'is-invalid' : ''}`"/>
                  <small v-if="validator.errors().has('bank_account')" class="invalid-feedback">
                    {{validator.errors().first('bank_account')}}
                  </small>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label class="form-label">Payment terms</label>
                    <div class="input-group">
                      <div class="input-group">
                        <input
                          v-model="item.payment_terms"
                          type="number"
                          min="1"
                          max="90"
                          data-test="txt-payment-terms"
                          class="form-control"/>
                        <div class="input-group-append">
                          <div class="input-group-text">days</div>
                        </div>
                        <small v-if="validator.errors().has('payment_terms')" class="invalid-feedback">
                          {{validator.errors().first('payment_terms')}}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label">Credit limit</label>
                    <div class="input-group">
                      <div class="input-group">
                        <input
                          v-model="item.credit_limit"
                          data-test="txt-credit-limit"
                          class="form-control"/>
                        <small v-if="validator.errors().has('credit_limit')" class="invalid-feedback">
                          {{validator.errors().first('credit_limit')}}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-4">
                    <label class="form-label">Settlement discount</label>
                    <div class="input-group">
                      <div class="input-group">
                        <input
                          v-model="item.settlement_discount"
                          data-test="txt-settlement-discount"
                          class="form-control"/>
                        <div class="input-group-append">
                          <div class="input-group-text">%</div>
                        </div>
                        <small v-if="validator.errors().has('settlement_discount')" class="invalid-feedback">
                          {{validator.errors().first('settlement_discount')}}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.id && can(uiPermissions.SUPPLIERS_CONTACTS_VIEW)" class="row">
          <div class="col">
            <supplier-contacts :supplier-id="item.id"/>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">HACCP / B Corp information</h3>
              </div>
              <div class="card-body">
                <div class="form-group">
                  <label>Vendor activity</label>
                  <select
                    v-model="item.assessment.vendor_activity"
                    data-test="txt-vendor-activity"
                    class="form-control">
                    <option :value="null">Select activity</option>
                    <option value="Food">Food</option>
                    <option value="Local">Local</option>
                    <option value="Import">Import</option>
                    <option value="Sales agent">Sales agent</option>
                    <option value="Distributor">Distributor</option>
                    <option value="Manufacturer">Manufacturer</option>
                    <option value="Waste management">Waste management</option>
                    <option value="Packaging">Packaging</option>
                    <option value="Chemicals">Chemicals</option>
                    <option value="Cleaning equipment">Cleaning equipment</option>
                    <option value="Stationary">Stationary</option>
                    <option value="Maintenance">Maintenance</option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="form-label text-capitalize">Has the supplier performed life cycle assessments for their
                    products?</label>
                  <h-switch
                    data-test="toggle-product-assessment"
                    :value="item.assessment.performs_product_life_cycle_assessment"
                    @input="(newValue) => item.assessment.performs_product_life_cycle_assessment = newValue"/>
                </div>
                <div v-if="item.assessment.performs_product_life_cycle_assessment" class="form-group">
                  <label class="form-label">If yes, what percentage of products have been through a lifecycle
                    assessment?</label>
                  <div class="input-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text">%</div>
                      </div>
                      <input
                        v-model="item.assessment.assessed_product_percentage"
                        data-test="txt-percentage"
                        class="form-control"/>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label class="form-label text-capitalize">Does the supplier have customers complaint system?</label>
                  <h-switch
                    data-test="toggle-customer-complaint-system"
                    :value="item.assessment.has_customer_complaints_system"
                    @input="(newValue) => item.assessment.has_customer_complaints_system = newValue"/>
                </div>
                <div class="form-group">
                  <label class="form-label text-capitalize">Has the supplier implemented a quality management
                    system?</label>
                  <h-switch
                    data-test="toggle-quality-mgmt-system"
                    :value="item.assessment.has_quality_management_system"
                    @input="(newValue) => item.assessment.has_quality_management_system = newValue"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <edit-footer
              :dirty="dirty"
              entity="supplier"
              :isNew="isNew"
              :submitting="submitting"
              :hide-delete="true">
            </edit-footer>
          </div>
        </div>
      </form>
      <attachments v-if="!loading && tab === 'attachments'" :supplier-id="item.id"/>
      <TradingTerms
        v-if="!loading && tab === 'trading-terms' && can(uiPermissions.SUPPLIERS_TRADING_TERMS_VIEW)"
        :supplier-id="item.id"
        :supplier-name="item.name">
      </TradingTerms>
    </dimmer>
  </div>
</template>

<script>
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import TimeRangePicker from '@/components/TimeRangePicker';
import edit from '@/mixins/edit';
import fetch from '@/mixins/fetch';
import loadsRelations from '@hellochef/shared-js/mixins/loadsRelations';
import {suppliers, countries} from '@/services';
import SupplierContacts from '@/views/suppliers/components/SupplierContacts';
import HSwitch from '@/components/ui/HSwitch';
import Attachments from '@/views/suppliers/tabs/Attachments';
import TradingTerms from '@/views/suppliers/tabs/TradingTerms';
import SupplierTabs from '@/views/suppliers/tabs/SupplierTabs';

const INITIAL_ASSESSMENT = {
  vendor_activity: null,
  performs_product_life_cycle_assessment: false,
  assessed_product_percentage: null,
  has_customer_complaints_system: false,
  has_quality_management_system: false,
};

export default {
  components: {
    SupplierTabs,
    Attachments,
    SupplierContacts,
    EditFooter,
    EditHeader,
    HSwitch,
    TimeRangePicker,
    TradingTerms,
  },
  mixins: [edit, fetch, loadsRelations],
  data() {
    return {
      tab: 'details',
      item: {
        name: '',
        legal_name: '',
        country_id: '',
        city: '',
        address: '',
        payment_terms: null,
        settlement_discount: '',
        credit_limit: '',
        tax_registration_number: '',
        trading_license_number: '',
        bank_account: '',
        notes: '',
        is_active: '',
        integration_status: '',
        delivery_time_from: '',
        delivery_time_to: '',
        assessment: INITIAL_ASSESSMENT,
      },
      validator: this.$bodyValidator,
      rules: {
        name: ['required', 'string'],
        legal_name: ['nullable', 'string'],
        country_id: ['nullable', 'integer'],
        city: ['nullable', 'string', 'max:255'],
        address: ['nullable', 'string', 'max:255', 'max:255'],
        payment_terms: ['nullable', 'integer', 'min:1', 'max:90'],
        settlement_discount: ['nullable', 'numeric'],
        credit_limit: ['nullable', 'numeric'],
        trading_license_number: ['nullable', 'string', 'max:255'],
        tax_registration_number: ['nullable', 'string', 'max:255'],
        bank_account: ['nullable', 'string', 'max:255'],
      },
      with: [
        'supplierAssessment',
      ],
      countries: [],
    };
  },
  computed: {
    route() {
      return `/suppliers/${this.item.id}`;
    },
    delivery_slot: {
      get() {
        return {
          startTime: this.item.delivery_time_from,
          endTime: this.item.delivery_time_to,
        };
      },
      set(newValue) {
        this.item.delivery_time_from = newValue.startTime;
        this.item.delivery_time_to = newValue.endTime;
      },
    },
  },
  created() {
    countries.getByParameters({column: 'name', direction: 'asc'}).then(result => {
      this.countries = result;
    });

    // to get the previous tab on refresh and back
    if (!this.isNew) {
      this.tab = this.$route.query.t ?? this.tab;
      this.$router.replace({query: {t: this.tab}});
    }
  },
  methods: {
    fetchData(id) {
      const params = {...this.withRelations()};
      return suppliers.getById(id, {params});
    },
    submitData(item) {
      this.validator.setData(item).setRules(this.rules);

      if (!this.validator.validate()) return;

      const params = {...this.withRelations()};

      return suppliers.saveOrUpdate(item, {params});
    },
    transformData(results) {
      if (!results.item.assessment) {
        results.item.assessment = INITIAL_ASSESSMENT;
      }
      return results;
    },
    setTab(t) {
      this.tab = t;
      this.$router.replace({query: {t: t}});
      this.resetData();
    },
  },
};
</script>
