<template>
  <div class="card">
    <div class="card-header mb-4">
      <h3 class="card-title">Contact information</h3>
    </div>
    <dimmer :active="loading">
      <table data-test="table-contacts" class="table table-hover">
        <thead>
          <tr>
            <th>Contact name</th>
            <th>Position</th>
            <th>Email</th>
            <th>Phone</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="contact in contacts" :key="contact.id">
            <td>{{contact.name}}</td>
            <td>{{contact.position}}</td>
            <td><a :href="`mailto:${contact.email}`">{{contact.email}}</a></td>
            <td>{{contact.phone}}</td>
            <td class="text-center">
              <div v-if="can([uiPermissions.SUPPLIERS_CONTACTS_DELETE])" class="item-action dropdown">
                <a data-toggle="dropdown" class="icon">
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <button
                    v-if="can(uiPermissions.SUPPLIERS_CONTACTS_DELETE)"
                    data-test="btn-delete-contact"
                    class="dropdown-item btn btn-link"
                    @click.prevent="handleDeleteContact(contact.id)">
                    <i class="dropdown-icon fe fe-delete text-danger"></i>
                    <span class="text-danger">Delete contact</span>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </dimmer>

    <b-modal v-model="newContactModal">
      <template #modal-header-close><wbr/></template>
      <dimmer :active="loading">
        <div class="container">
          <div class="row">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label>Name <span class="text-danger">*</span></label>
                <input
                  v-model="newContact.name"
                  data-test="txt-name"
                  :class="`form-control ${validator.errors().has('name') ? 'is-invalid' : ''}`">
                <div v-if="validator.errors().has('name')" class="invalid-feedback">
                  {{validator.errors().first('name')}}
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Position</label>
                <input
                  v-model="newContact.position"
                  data-test="txt-position"
                  class="form-control">
              </div>
              <div class="form-group col-md-6">
                <label>Email <span class="text-danger">*</span></label>
                <input
                  v-model="newContact.email"
                  data-test="txt-email"
                  :class="`form-control ${validator.errors().has('email') ? 'is-invalid' : ''}`">
                <div v-if="validator.errors().has('email')" class="invalid-feedback">
                  {{validator.errors().first('email')}}
                </div>
              </div>
              <div class="form-group col-md-6">
                <label>Phone</label>
                <input
                  v-model="newContact.phone"
                  data-test="txt-phone"
                  :class="`form-control ${validator.errors().has('phone') ? 'is-invalid' : ''}`">
                <div v-if="validator.errors().has('phone')" class="invalid-feedback">
                  {{validator.errors().first('phone')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </dimmer>
      <template #modal-footer>
        <button
          data-test="btn-create"
          class="btn btn-success"
          @click.prevent="handleCreateContact">
          Create
        </button>
        <button
          data-test="btn-cancel"
          class="btn btn-danger"
          @click.prevent="newContactModal = false">
          Cancel
        </button>
      </template>
    </b-modal>

    <div v-if="can(uiPermissions.SUPPLIERS_CONTACTS_CREATE)" class="card-footer">
      <button
        class="btn btn-primary"
        data-test="btn-add-contact"
        @click.prevent="newContactModal = true">
        Add contact
      </button>
    </div>
  </div>
</template>

<script>
import {suppliers} from '@/services';

export default {
  name: 'SupplierContacts',
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      newContactModal: false,
      contacts: [],
      newContact: {},
      validator: this.$bodyValidator,
      rules: {
        name: ['required'],
        email: ['required', 'email'],
        phone: ['nullable', 'string', 'emirates_number'],
      },
    };
  },
  async created() {
    await this.fetchContacts();
  },
  methods: {
    async fetchContacts() {
      this.loading = true;
      try {
        const {contacts} = await suppliers.getContacts(this.supplierId);
        this.contacts = contacts;
      }
      finally {
        this.loading = false;
      }
    },
    async handleCreateContact() {
      this.validator.setData(this.newContact).setRules(this.rules);

      if (!this.validator.validate()) {
        return;
      }

      try {
        this.loading = true;
        await suppliers.createContact(this.supplierId, this.newContact);
        this.newContact = {};
        this.newContactModal = false;
        this.$toasted.success('Contact added');
        await this.fetchContacts();
      }
      catch (e) {
        this.$toasted.error(e);
      }
      finally {
        this.loading = false;
      }
    },
    async handleDeleteContact(id) {
      if (window.confirm('Are you sure that you want to delete this contact?')) {
        try {
          await suppliers.deleteContact(this.supplierId, id);
          this.$toasted.success('Contact permanently deleted');
          await this.fetchContacts();
        }
        catch (e) {
          this.$toasted.error(e);
        }
      }
    },
  },
};
</script>
