<template>

  <div>
    <date-picker
      v-model="range"
      format="hh:mm A"
      value-type="HH:mm:ss"
      type="time"
      range
      range-separator="-"
      placeholder="Select Time Range"
      :show-time-header="true"
      time-title-format="Pick"
      :shortcuts="[]"
      input-class="form-control pr-6"
      class="w-100"></date-picker>

  </div>

</template>

<script>

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'TimeRangePicker',
  components: {

    DatePicker,
  },
  props: [

    'value',
  ],
  computed: {

    range: {

      get() {
        return [this.value.startTime, this.value.endTime];
      },
      set(newValue) {
        this.$emit('input', {
          startTime: newValue[0],
          endTime: newValue[1],
        });
      },
    },
  },
};

</script>
